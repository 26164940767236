import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { ProductView } from '@components';
import { BUILD_FLOW_STEP_DIRECTION, CUSTOMER_JOURNEYS, FRAME_COLORS, MIX_MATERIAL_SWATCHES_OPTIONS } from '@constants';
import { useBFContext } from '@context';
import { getVariantByOption } from '@utils/shopify';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';

const BaseFramePDPSidebar = ({ journey, yotpo, frameImages, VTODispatch, selectedMaterial, setSelectedMaterial }) => {
	const { asPath, push, query } = useRouter();
	const { product, subtotal, frameColor, setFrameColor, lensColor, setStepChange, setSelectedLenses } = useBFContext();
	const {
		description,
		name,
		type,
		variantImages,
		metafields: { frameSizings, lensColors },
	} = product;

	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const selectedVariant = getVariantByOption(product, frameColor);
	const variantDescription = selectedVariant?.description ?? description;

	const swatchOptions = selectedMaterial.includes('Mixed Material')
		? MIX_MATERIAL_SWATCHES_OPTIONS.filter(option => option.metal).map(option => option.metal)
		: MIX_MATERIAL_SWATCHES_OPTIONS.map(option => option.acetate);

	const handleSwatchChange = useCallback(
		payload => {
			if (payload.type === 'lens') {
				push({ query: { ...query, lensColor: encodeURI(payload.option) } }, undefined, { shallow: true });
				setSelectedLenses([`Sun - ${payload.option}` as BASE_FRAME_LENS_OPTIONS]);
			}
			if (payload.type === 'frame') {
				push({ query: { ...query, frameColor: encodeURI(payload.option) } }, undefined, { shallow: true });
				setFrameColor(payload.option);
			}
		},
		[query]
	);

	const handleMaterialChange = useCallback(
		(material, frameColor) => {
			let acetateColor = '';

			switch (frameColor) {
				case FRAME_COLORS.MIX_CRYSTAL_SILVER:
					acetateColor = FRAME_COLORS.CRYSTAL_CLEAR;
					break;
				case FRAME_COLORS.MIX_BLACK_GOLD:
					acetateColor = FRAME_COLORS.BLACK;
					break;
				default:
					acetateColor = frameColor;
					break;
			}

			push(
				{ query: { ...query, frameColor: material === 'Acetate' ? encodeURI(acetateColor) : encodeURI(material) } },
				undefined,
				{ shallow: true }
			);
			setSelectedMaterial(material);
			setFrameColor(material === 'Acetate' ? (acetateColor as FRAME_COLORS) : (frameColor as FRAME_COLORS));
		},
		[query, frameColor]
	);

	return (
		<div>
			<ProductView.Sidebar
				path={asPath}
				name={name}
				subtotal={subtotal}
				productType={type}
				product={product}
				selectedVariant={selectedVariant}
				yotpo={yotpo}
				primaryController={{
					controllerType: 'frame',
					title: 'Base Frame Color',
					selected: frameColor,
					options: swatchOptions,
					callback: handleSwatchChange,
					variantImages: variantImages,
				}}
				{...(SUNGLASSES_JOURNEY && {
					secondaryController: {
						controllerType: 'lens',
						title: 'Lens Color',
						selected: lensColor,
						options: lensColors.options,
						callback: handleSwatchChange,
						variantImages: undefined,
					},
				})}
				aboutProps={{
					description: variantDescription,
					frameSizings,
					name,
					primaryImage: frameImages[0],
					productType: type,
				}}
				buttonProps={{
					availableForSale: product.availableForSale,
					dataTags: { 'data-open-buildflow': true, 'data-testid': 'buildAndPurchase' },
					hasSubtotal: true,
					label: 'Build & Purchase',
					onClick: () => setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT),
					withChevron: true,
					margin: '0',
				}}
				shipmentInfo={selectedVariant?.metafields?.shipmentInfo ?? null}
				VTODispatch={VTODispatch}
				isSunGlasses={SUNGLASSES_JOURNEY}
				selectedMaterial={selectedMaterial}
				handleMaterialChange={handleMaterialChange}
			/>
		</div>
	);
};

export default BaseFramePDPSidebar;
