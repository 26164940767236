/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { TOAST, PRODUCT_TYPES } from '@constants';
import {
	BaseFramePDPDetails,
	BaseFramePDPSidebar,
	BuildFlow,
	Carousel,
	ErrorBoundary,
	Modal,
	ProductView,
	FakeRecommendationCarousel,
	StepTracker,
	VTO,
} from '@components';
import {
	generateSunglassImages,
	getVariantByOption,
	snakeCase,
	useCollectionsQuery,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	VTO_SRC,
	FRAME_COLORS,
	FRAME_COLOR_HANDLES,
	LOCALE_DICT,
} from '@utils/index';
import { BaseFrameState, baseFrameReducer } from '@utils/hooks/useBaseFrame';
import { useDebounce, useHasMounted, useLockedBody } from '@utils/hooks';
import { useTailoredExperience } from '@services/contentful';
import { trackProductView } from '@services/analytics/trackers';
import { useBFContext, useToastContext } from '@context';
import { CollectionListFields } from '@ts/contentful';

const YotpoReviews = dynamic(() => import('@components').then(mod => mod.YotpoReviews));

const BaseFramePDP = ({ journey, lifestyleImages, yotpo, sunglassProduct = null }) => {
	const {
		collectionListSlug,
		setCollectionListSlug,
		frame,
		frameColor,
		lensColor,
		setVariantImages,
		setDefaultLens,
		product,
		step,
		setFrameColor,
	} = useBFContext();
	const [images, setImages] = useState([]);
	const { query, beforePopState, locale, push } = useRouter();
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);

	const hasMounted = useHasMounted();
	const { showToast } = useToastContext();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const isEditMode = Boolean(query?.edit);

	// Product Info
	const { name, tags, type, variantImages } = product;
	const selectedVariant = getVariantByOption(product, frameColor);

	const initialVtoState: BaseFrameState = {
		product: product,
		variant: product.variants[0],
		frame: product.variants[0].option as FRAME_COLORS,
		lens: null,
		image: product.variants[0].image.url,
	};

	const isMetalFrameColor = [FRAME_COLORS.MIX_BLACK_GOLD, FRAME_COLORS.MIX_CRYSTAL_SILVER].includes(frameColor);
	const productVideo = selectedVariant?.metafields?.productVideo;
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);
	const [selectedMaterial, setSelectedMaterial] = useState(isMetalFrameColor ? frameColor : 'Acetate');

	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;

	const { data: tailoredExperience } = useTailoredExperience();
	if (!!tailoredExperience?.collectionList) {
		setCollectionListSlug((tailoredExperience.collectionList?.fields as CollectionListFields)?.slug);
	}

	useCollectionsQuery(collectionListSlug, {
		queryKey: `build-flow-collections-${frame}`,
		queryRefreshVars: [collectionListSlug, locale],
		type: 'buildflow',
		withProducts: true,
		skipVariants: true,
		includeSpecificFrameVariant: true,
		selectedOptions: [
			{
				name: 'Frame',
				value: frame,
			},
		],
		country: countryCode,
	});

	const isBuildFlowOpen = step > BUILD_FLOW_STEPS.COLOR;

	const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
	isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');

	const debouncedTrackProductView = useDebounce(trackProductView);
	useEffect(() => {
		debouncedTrackProductView({
			variant: selectedVariant,
			path: `/${journey}/${query.demo}/`,
		});
	}, [selectedVariant]);

	const sunglassImageCallback = useCallback(({ name, baseColor, lensColor, length }) => {
		return generateSunglassImages({ name, baseColor, lensColor, length });
	}, []);

	const frameImages = useMemo(
		() =>
			SUNGLASSES_JOURNEY
				? sunglassImageCallback({ name, baseColor: frameColor, lensColor: lensColor, length: 3 })
				: variantImages[frameColor],
		[product, frameColor, lensColor]
	);

	useLockedBody(isBuildFlowOpen);

	useEffect(() => {
		if (lifestyleImages) {
			let carouselUrl = lifestyleImages.carousel.url;
			const frameColorHandle = Object.entries(FRAME_COLORS).find(([key, value]) => value === frameColor)?.[0];

			if (isMetalFrameColor && frameColorHandle) {
				const handleValue = FRAME_COLOR_HANDLES[frameColorHandle];
				carouselUrl = carouselUrl.replace('.png', `-${handleValue}.png`);
			}

			const image = new Image();
			image.onload = () => setImages([...frameImages, { ...lifestyleImages.carousel, url: carouselUrl }]);
			image.onerror = () => setImages([...frameImages]);
			image.src = carouselUrl;
		}
	}, [frameImages, lifestyleImages, isMetalFrameColor]);

	useEffect(() => {
		SUNGLASSES_JOURNEY &&
			sunglassProduct &&
			setDefaultLens(sunglassProduct.variants.find(lens => lens.sku === `SUN_LENS__${snakeCase(lensColor.toUpperCase())}`));
	}, [lensColor]);

	useEffect(() => {
		setVariantImages(frameImages.slice(0, -1));
	}, [frameImages]);

	useEffect(() => {
		if (isEditMode) showToast(TOAST.EDIT_MODE);
	}, [isEditMode]);

	useEffect(() => {
		const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
		isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');
	}, [step]);

	useEffect(() => {
		if (isMetalFrameColor) {
			setSelectedMaterial(frameColor);
		} else {
			setSelectedMaterial('Acetate');
		}
	}, [frameColor]);

	useMemo(() => {
		beforePopState(() => {
			header.style.display = '';
			return true;
		});
	}, []);

	if (!hasMounted) return;

	return (
		<Modal>
			{!isBuildFlowOpen ? (
				<ProductView.Root
					seo={{
						product: {
							...product,
							aggregateRating: yotpo,
							images: frameImages,
							suggestedGender: query.demo as string,
							variants: [selectedVariant],
						},
					}}
				>
					<ProductView.Images>
						<StepTracker />
						<Carousel
							images={images}
							video={productVideo}
							name={name}
							tags={tags}
							type={type}
							selectedImgIndex={selectedImgIndex}
							setSelectedImgIndex={setSelectedImgIndex}
							variant={selectedVariant}
						/>
					</ProductView.Images>
					<BaseFramePDPSidebar
						frameImages={frameImages}
						yotpo={yotpo}
						journey={journey}
						VTODispatch={!SUNGLASSES_JOURNEY && setVtoState}
						selectedMaterial={selectedMaterial}
						setSelectedMaterial={setSelectedMaterial}
					/>
					<BaseFramePDPDetails journey={journey} selectedMaterial={selectedMaterial} />
					<ProductView.Recommendations>
						<ErrorBoundary>
							<FakeRecommendationCarousel
								variant={selectedVariant}
								selectedFrame={frame}
								headingText={`Similar to ${name}`}
								productTypes={[PRODUCT_TYPES.BASE_FRAME]}
								demo={query.demo as string}
							/>
						</ErrorBoundary>
					</ProductView.Recommendations>
					<ProductView.Reviews>
						<YotpoReviews product={product} />
					</ProductView.Reviews>
				</ProductView.Root>
			) : (
				<BuildFlow />
			)}
			{!SUNGLASSES_JOURNEY && (
				<>
					<Modal.Content
						removePadding
						customClose
						onCloseAutoFocus={e => e.preventDefault()}
						style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
					>
						<VTO
							FM={FitMix}
							state={vtoState}
							setState={setVtoState}
							pathInfo={`/${journey}/${query.demo}`}
							showBaseFrameOption={false}
							selectedMaterial={selectedMaterial}
							showMaterialOption
							setSelectedMaterial={setSelectedMaterial}
							setFrameColor={frameColor => {
								setFrameColor(frameColor);
								push({ query: { ...query, frameColor: encodeURI(frameColor as string) } }, undefined, {
									shallow: true,
								});
							}}
						/>
					</Modal.Content>
					<Script
						src={VTO_SRC}
						strategy='lazyOnload'
						onLoad={() => {
							setFitMix(window.FitMix);
						}}
					></Script>
				</>
			)}
		</Modal>
	);
};

export default BaseFramePDP;
